import "./polyfills";

async function app() {
  const bootstrapImport = import('src/bootstrap');
  const { bootApplication } = await bootstrapImport;
  const config = await bootApplication();
  console.log("booted =>", config);

  const initalizeAppImport = import('src/bootstrap/initializeApp');
  const { initializeApp } = await initalizeAppImport;
  initializeApp();
}

app();